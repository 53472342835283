import React from 'react'
import Layout from '../components/Layout'
import Index from '../page/Index/Index'

const IndexPage = () => (
  <Layout>
    <Index />
  </Layout>
)

export default IndexPage
